<template>
    <el-drawer
        title="修改登录密码"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                <el-form-item v-if="isPwd == 1" prop="sourcePwd">
					<span slot="label">原密码: </span>
                    <el-input v-model="formData.sourcePwd" type="password" placeholder="请输入原密码" size="medium"></el-input>
                </el-form-item>
                <el-form-item prop="newPwd">
                    <span slot="label">新密码: <small>8-40个字符，且必须包含至少1位字母、1位数字</small></span>
                    <el-input v-model="formData.newPwd" type="password" placeholder="请输入新密码" size="medium" maxlength="40" minlength="8"></el-input>
                </el-form-item>
                <el-form-item prop="newPwd2" label="确认密码:">
                    <el-input v-model="formData.newPwd2" type="password" placeholder="请确认密码" size="medium"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
var md5 = require('js-md5');

const defaultFormData = {
    newPwd:'',
    newPwd2:'',
    sourcePwd:''
}
export default {
    props:{
        userId:{
            type:[String,Number],
            default:''
        },
        isPwd:{
            type:[String,Number],
            default:0
        }
    },
    data(){
        //验证密码
       const validatePassword = (rule, value, callback) => {
            const _reg = /^(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 _]{8,40}$/;
            if (!_reg.test(value)) {
                callback(new Error("登录密码必须8-40个字符，且必须包含至少1位字母、1位数字"));
            } else {
                callback();
            }
        }
        //验证确认密码
        const validateNewPwd2 = (rule, value, callback) => {
            if (value !== this.formData.newPwd) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        }
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{
                ...defaultFormData
            },
            rules:{
                sourcePwd:[
                    //{required:this.password ? true : false, message:'请输入原密码'},
					{required:true, message:'请输入原密码'},
                ],
                newPwd:[
                    {required:true,message:'请输入新密码'},
                    {validator:validatePassword}
                ],
                newPwd2:[
                    {required:true,message:'请确认新密码'},
                    {validator:validateNewPwd2}
                ]
            }
        }
    },
    methods:{
        close(){
            this.formData = {...defaultFormData};
            this.$refs.formData.resetFields();
            this.drawerShow = false;
        },
        show(){
            this.drawerShow = true;
        },
        submitForm(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true
                    this.$request({
                        url: "/userInfo/updatePassword",
                        params: {
                            ...this.formData,
                            userId:this.userId,
                            newPwd: md5(this.formData.newPwd).toLowerCase(),
                            newPwd2: md5(this.formData.newPwd2).toLowerCase(),
                            sourcePwd: md5(this.formData.sourcePwd).toLowerCase(),
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                //this.$emit('update:password', 1);
                                this.$message.success("操作成功");
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });    
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>