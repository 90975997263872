<template>
    <div v-loading="dataLoading">
        <auth-tips></auth-tips>
        <div class="page-group-box">
            <div class="page-group-title">登录账户</div>
            <div class="page-group-content">
                <div class="box clearfix">
                    <span class="iconfont fl">&#xe634;</span>
                    <div class="info">
                        <div class="item">
                            <span class="color-regular">自定义登录名:</span>
                            <span class="text">{{data && data.loginName}}</span>
                            <el-button type="text" size="mini" @click="$refs.loginNameEdit.show()">修改</el-button></div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="box clearfix">
                    <span class="iconfont fl">&#xe635;</span>
                    <div class="info">                    
                        <div class="item">
                            <span class="color-regular">登录密码:</span> 
                            <span class="text">******</span>
                            <el-button type="text" @click="$refs.loginPasswordEdit.show()">修改</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-group-box MT10">
            <div class="page-group-title">负责人信息</div>
            <div class="page-group-content">
                <div class="box clearfix">
                    <span class="iconfont fl">&#xe638;</span>
                    <div class="info">
                        <div class="item">
                            <span class="color-regular">负责人手机:</span>
                            <span class="text" v-if="data && data.telephone">{{ data.telephone.includes('tmp') ? '' : data.telephone }}</span>
                            <el-button type="text" size="mini" @click="$refs.contactTelephpneEdit.show()">修改</el-button></div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="box clearfix">
                    <span class="iconfont fl">&#xe636;</span>
                    <div class="info">                    
                        <div class="item">
                            <span class="color-regular">负责人邮箱:</span> 
                            <span class="text">{{data && data.contactMail}}</span>
                            <el-button type="text" @click="$refs.contactEmailEdit.show()">修改</el-button>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="box clearfix">
                    <span class="iconfont fl">&#xe637;</span>
                    <div class="info">                    
                        <div class="item">
                            <span class="color-regular">负责人姓名:</span> 
                            <span class="text">{{data && data.contactName}}</span>
                            <el-button type="text" @click="$refs.contactNameEdit.show()">修改</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <login-name-edit v-if="data" ref="loginNameEdit" :userId="userId" :loginName.sync="data.loginName"></login-name-edit>
        <login-password-edit v-if="data" ref="loginPasswordEdit" :userId="userId" :isPwd.sync="data.isPwd"></login-password-edit>
        <contact-name-edit v-if="data" ref="contactNameEdit" :userId="userId" :contactName.sync="data.contactName"></contact-name-edit>
        <contact-email-edit v-if="data" ref="contactEmailEdit" :userId="userId" :contactMail.sync="data.contactMail"></contact-email-edit>
        <contact-telephone-edit v-if="data" ref="contactTelephpneEdit" :userId="userId" :telephone.sync="data.telephone"></contact-telephone-edit>
    </div>
</template>


<script>
import AuthTips from '@/components/AuthTips';
import LoginNameEdit from './components/login-name-edit';
import LoginPasswordEdit from './components/login-password-edit';
import ContactNameEdit from  './components/contact-name-edit';
import ContactEmailEdit from  './components/contact-email-edit';
import ContactTelephoneEdit from  './components/contact-telephone-edit';
export default {
    components:{AuthTips,LoginNameEdit,LoginPasswordEdit,ContactNameEdit,ContactEmailEdit,ContactTelephoneEdit},
    data(){
        return{
            dataLoading:false,
            userId: this.$store.state.userInfo.id,
            data:null
        }
    },
    mounted(){
        this.getAccountInfo();
    },
    methods:{
        getAccountInfo(){
            this.dataLoading = true
            this.$request({
                url: "/userInfo/getAccountInfo",
                params: {userId:this.userId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result
                    } else {
                        this.$message.error(msg || "获取用户信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取用户信息失败");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.page-group-content{
    margin: 10px 0 0 10px;
    .box{
        padding: 25px 0;
        .iconfont{
            font-size: 24px;
            margin-right: 36px;
            // line-height: 28px;
        }
        .info{
            overflow: hidden;
            font-size: 16px;
            line-height: 24px;
            .el-button{
                font-size: 16px;
                padding: 0;
            }
            .text{
                margin: 0 30px;
            }
        }
    }
    .el-divider{
        background-color: #EBEEF5;
        margin: 0 0 0 60px;
        width: auto;
    }
}

</style>