<template>
    <el-drawer
        title="修改负责人手机"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                <el-form-item prop="tel">
                    <span slot="label">负责人手机:</span>
                    <el-input v-model="formData.tel" placeholder="请输入负责人手机号码" size="medium"></el-input>
                </el-form-item>
                <el-form-item prop="securityCode" label="短信验证码">
                    <div class="input-with-button">
                        <el-input v-model="formData.securityCode" placeholder="请输入短信验证码" size="medium">
                            <el-button
                                slot="append"
                                native-type="button"
                                type="text"
                                @click="getCode()"
                                :disabled="codeBtn.disabled"
                                :loading="codeBtn.loading"
                            >{{codeBtn.btnText}}</el-button>
                        </el-input>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import {fetchCode, verificationSwitch} from '@/assets/js/commonApi';
export default {
    props:{
        userId:{
            type:[String,Number],
            default:''
        },
        telephone:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{
                tel: '',
                securityCode:''
            },
            rules:{
                tel:[
                    {required:true,message:'请输入负责人手机号码'},
                    {validator: this.$utils.validatePhone}
                ],
                securityCode:[{required: true, message: '请输入短信验证码'}],
            },
            codeBtn: {
                btnText: "获取验证码",
                disabled: false,
                loading: false
            },
            captcha: null,
        }
    },
    created(){
        const that = this;
        this.$utils.loadScript(
            {
                id:'tcaptcha',
                url:'https://turing.captcha.qcloud.com/TCaptcha.js'
            },
            () =>{
                // eslint-disable-next-line no-undef
                this.captcha = new TencentCaptcha(
                    "2032912416", 
                    function(res){
                        if(res.ret === 0){
                            that.getCodeEvent( res );
                        }
                    }
                );
            }
        )
    },
    methods:{
        close(){
            this.$refs.formData.resetFields();
            this.drawerShow = false;
        },
        show(){
            this.drawerShow = true;
        },
        //获取短信验证码
        getCode() {
            this.$refs["formData"].validateField(["tel"], async (err) => {
                if(!err){
                    const res = await verificationSwitch(); //获取验证码设置
                    const { state, msg, result } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "获取验证码设置失败");
                    } else {
                        if( result.suduTelephone ){
                            this.captcha.show();
                        }else{
                            this.getCodeEvent();
                        }
                    }
                }
            });
        },
        getCodeEvent(_res){
            this.codeBtn.loading = true;
            fetchCode({
                biz:'up_contact_phone',
                phone: this.formData.tel,
                randStr:_res && encodeURIComponent(_res.randstr) || '',
                ticket: _res && encodeURIComponent(_res.ticket) || '',
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "请求失败");
                    } else {
                        // this.formData.securityCode = res.data.result;
                        this.dtime(60)
                    }
                })
                .catch(() => {
                    this.$message.error('请求错误');
                }).finally( () => {
                    this.codeBtn.loading = false;
                });
        },
        dtime( time ){
            var that = this , _time = time;
            if( _time <= 0 ){
                this.codeBtn.btnText = '获取验证码';
                this.codeBtn.disabled = false;
            }else{
                this.codeBtn.btnText =  _time+'秒后重试' ;
                _time --;
                this.codeBtn.disabled = true;
                let t1 = setTimeout(function(){ that.dtime( _time );clearTimeout(t1); } , 1000);
            }
        },
        submitForm(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true
                    this.$request({
                        url: "/userInfo/updateTel",
                        params: {
                            ...this.formData,
                            userId:this.userId
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('update:telephone',this.formData.tel);
                                this.$message.success("操作成功");
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });    
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
    .input-with-button{
        // ::v-deep .el-input-group__append{
        //     background: transparent;
        //     color: $--color-theme;
        // }
        .el-button{
            padding: 0 10px;
        }
    }
</style>