<template>
    <el-drawer
        title="修改负责人姓名"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                <el-form-item prop="contactName">
                    <span slot="label">负责人姓名:</span>
                    <el-input v-model="formData.contactName" placeholder="请输入负责人姓名" size="medium" maxlength="40"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    props:{
        userId:{
            type:[String,Number],
            default:''
        },
        contactName:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{
                contactName: '',
            },
            rules:{
                contactName:[
                    {required:true,message:'请输入负责人姓名'},
                ]
            }
        }
    },
    methods:{
        close(){
            this.$refs.formData.resetFields();
            this.drawerShow = false;
        },
        show(){
            this.drawerShow = true;
        },
        submitForm(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true
                    this.$request({
                        url: "/userInfo/updateContactName",
                        params: {
                            ...this.formData,
                            userId:this.userId
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('update:contactName',this.formData.contactName);
                                this.$message.success("操作成功");
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });    
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>